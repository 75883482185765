import React from "react";
import { useTranslation } from "react-i18next";
import { withRouter, useLocation } from "react-router-dom";
import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Logo from "components/Logo";
import LanguageSwitch from "components/shared/language/languageSwitch";
import Footer from "components/Footer";
import CookiesConsent from "components/shared/CookiesConsent";
import queryString from "query-string";
import { DeprecationCard } from "deprecationCard";

const PublicLayout = ({ formType, children }) => {
  const location = useLocation();
  const { lang } = queryString.parse(location.search);
  const { t } = useTranslation();

  const isActivePage = location.pathname === '/login' || location.pathname === '/leasingRequest/business';

  const getDefaultLang = () => {
    switch (formType) {
      case "invoice-verification":
        return undefined;
      case "bank-statement":
      case "password-change":
        return getParamsLanguage(lang);
      default:
        return "lt";
    }
  };

  const getAvailableLanguages = () => {
    switch (formType) {
      case "leasing-request":
      case "partner":
        return ["lt"];
      default:
        return ["en", "lt", "lv", "ee"];
    }
  };

  const getParamsLanguage = (language) => {
    if (language && getAvailableLanguages().includes(language.toLowerCase())) {
      return language.toLowerCase();
    }

    if (language?.toLowerCase() === "et") {
      return "ee";
    }

    return "en";
  };

  return (
    <div className={`App publicLayout ${formType && formType !== "password-change" && "formLayout"} sme-theme`}>
      {isActivePage ? null : <DeprecationCard globalBanner />}
      {formType ? (
        <>
          <header className="App-header">
            <Row justify="center">
              <Col xs={20} sm={20} md={18} lg={14}>
                <div className="headerContent">
                  <Logo type="smeWhite" responsive />
                  <p>{t("welcomeText")}.</p>
                  <LanguageSwitch
                    availableLanguages={getAvailableLanguages()}
                    responsive={true}
                    defaultLang={getDefaultLang()}
                    useLocalStorage={formType === "invoice-verification" ? false : true}
                    jivochatEnabled={
                      formType === "invoice-verification" || formType === "password-change" ? true : false
                    }
                  />
                </div>
              </Col>
            </Row>
          </header>
          <section>
            <Row justify="center">
              <Col xs={22} sm={20} md={18} lg={14}>
                <div id="content">{children}</div>
              </Col>
            </Row>
          </section>
          {formType === "invoice-verification" ? <Footer /> : null}
        </>
      ) : (
        <>
          <header className="App-header">
            <Row>
              <Col span={14} offset={5}>
                <div className="headerContent">
                  <Logo type="smeWhite" />
                  <p>{t("welcomeText")}.</p>
                  <LanguageSwitch availableLanguages={getAvailableLanguages()} useLocalStorage={true} />
                </div>
              </Col>
            </Row>
          </header>
          <section>
            <Row>
              <Col span={14} offset={5}>
                <div id="content">{children}</div>
              </Col>
            </Row>
          </section>
          <Footer />
        </>
      )}
      <CookiesConsent />
    </div>
  );
};

export default withRouter(PublicLayout);
