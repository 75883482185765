import React from 'react';
import { useTranslation } from 'react-i18next';

import { ExclamationOutlined } from "@ant-design/icons";

import "./deprecation-styles.scss";


const beforeDecemberFirst = {
  lt: {
    header: "Ši savitarnos platforma bus uždaroma visam laikui nuo Gruodžio 1 d.",
    message: "Norime pranešti, kad 2023 m. gruodžio 1 d. ši platforma bus visam laikui uždaryta. Suprantame, kad nerimaujate dėl savo duomenų, tačiau galime jus užtikrinti, kad neprarasite jokios savo informacijos. Jei negalėsite prisijungti prie naujos platformos, susisiekite "
  },
  en: {
    header: "We are closing this platform permanently at December 1",
    message: "We want to inform you that this platform will be closed permanently on 2023 December 1. We understand your concern about your data, but rest assured, you won't lose any of your information. If you will be unable to login to new platform contact support "
  },
  lv: {
    header: "1.decembrī šī platforma tiks slēgta.",
    message: "Vēlamies Jūs informēt, ka šī platforma tiks neatgriezeniski slēgta 2023. gada 1. decembrī. Mēs saprotam Jūsu bažas par Jūsu datiem, taču esiet drošs – Jūs nezaudēsiet savu informāciju. Ja Jums neizdodas pieteikties jaunajā platformā, lūdzu sazinieties ar atbalsta dienestu "
  },
  ee: {
    header: "Sulgeme vana iseteeninduse platvormi lõplikult 1. detsembril.",
    message: "Anname teada, et see platvorm suletakse lõplikult 1. detsembril 2023. Teie andmed kantakse üle meie uude süsteemi. Kui Teil ei õnnestu uude iseteenindusse sisse logida, siis võtke ühendust meie klienditoega "
  }
}

export const DeprecationCard = ({ globalBanner }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={`deprecation-message_container ${globalBanner ? "global-banner" : ""}`}>
      <div className="icon-container">
        <ExclamationOutlined />
      </div>
      <div className={`message-content-container ${globalBanner ? "global-banner-message" : ""}`}>
        <div className="message_container">
          <div className="warning-header">
            {beforeDecemberFirst[i18n.language]?.header || beforeDecemberFirst.en.header}
          </div>
          <div className="warning-message">
            {beforeDecemberFirst[i18n.language]?.message || beforeDecemberFirst.en.message} <a href="mailto:info@smefinance.eu">info@smefinance.eu</a>.
          </div>
        </div>
        <a href={`${t("smeGoUrl")}`}>
          <button className="ant-btn button button--primary danger">
            <span>{t("smeGoButtonText")}</span>
          </button>
        </a>
      </div>
    </div>
  );
}